/* eslint-disable no-use-before-define */
/* eslint-disable import/order */
/*
 * @Author: gvillena
 * @Date: 2020-06-26 02:32:28
 * @Last Modified by: gvillena
 * @Last Modified time: 2020-06-26 02:51:04
 */
/* eslint-disable spaced-comment */

import {
  Button,
  Col,
  Icons,
  Layout as LayoutBase,
  Modal,
  Row,
  Spin,
  notification,
} from 'fractalia-design';
import { StatusTypes } from 'react-async';
import { useIdentity } from 'fractalia-provisioner/identity';
import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';

import { useApp } from '../../../app/hooks/useApp';
import Actions from '../../shared/components/Actions';
import columns from '../shared/columns';
import GettingStarted from '../../shared/components/GettingStarted';
import ProjectCodeSelector from '../../shared/components/ProjectCodeSelector';
import useProjectCodeValidator from '../../shared/hooks/useProjectCodeValidator';
import useRowValidator from '../../shared/hooks/useRowValidator';
import UsersLoader from '../../shared/components/UsersLoader';
import UsersTable from '../../shared/components/UsersTable';
import useSmartsupport from '../hooks/useSmartsupport';

import PlantillaSmartsupport from '../files/SMARTSUPPORT.xlsx';
import PlantillaSmartsupportSimple from '../files/SMARTSUPPORT_SIMPLE.xlsx';

const Layout = styled(LayoutBase)`
  background-color: #fff;
  width: 100%;
`;

const { Header: HeaderBase, Content: ContentBase } = LayoutBase;

const Content = styled(ContentBase)`
  display: flex;
  justify-content: center;
`;

//========================================================================================
/*                                                                                      *
 *                                        Header                                        *
 *                                                                                      */
//========================================================================================

const Header = styled(HeaderBase)`
  background-color: #fff;
  color: #1890ff;
  height: 100px;
  display: flex;
  justify-content: space-between;
  flex-directions column;
  align-items: center;
  font-size: 1.5rem;
  font-weight: 500
  `;

//========================================================================================
/*                                                                                      *
 *                              SmartsupportProvisionerPage                             *
 *                                                                                      */
//========================================================================================

function SmartsupportProvisionerPage() {
  // ────────────────────────────────────────────────────────────────────────────────
  // ─── constants ──────────────────────────────────────────────────────────────────
  // ────────────────────────────────────────────────────────────────────────────────

  const gettingStartedProps = {
    onStartClick: onStartClick,
    title: 'Provisionador Smartsupport',
    text:
      'Bienvenido usuario, con la ayuda de esta herramienta usted podra provisionar usuarios en la plataforma Smartsupport',
    templateFile: PlantillaSmartsupport,
    templateFileSimple: PlantillaSmartsupportSimple
  };

  // ────────────────────────────────────────────────────────────────────────────────────────
  // ──── state ─────────────────────────────────────────────────────────────────────────────
  // ────────────────────────────────────────────────────────────────────────────────────────

  const [users, setUsers] = useState({ data: [], file: null, headers: [] });
  const [tableColumns, setTableColumns] = useState([]);
  const [invalidRowsFounded, setInvalidRowsFounded] = useState([]);
  const [starterVisible, setStarterVisible] = useState(false);
  const [restVisible, setRestVisible] = useState(false);
  const [projectCode, setProjectCode] = useState();

  //* ────────────────────────────────────────────────────────────────────────────────
  //* ──── hooks ─────────────────────────────────────────────────────────────────────
  //* ────────────────────────────────────────────────────────────────────────────────

  const app = useApp();

  const { user } = useIdentity();

  const { checkInvalidRows, reduceInvalidRows } = useRowValidator(columns);

  const {
    smartsupportProvisioningSubmit: submit,
    smartsupportProvisioningSubmitRequested: submitRequested,
    smartsupportProvisioningSubmitSucceeded: submitSucceeded,
    smartsupportProvisioningSubmitFailed: submitFailed,
  } = useSmartsupport();

  const {
    projectCodeList,
    projectCodeGetAllSubmit,
    projectCodeGetAllSubmitRequested,
    projectCodeGetAllSubmitSucceeded,
    projectCodeGetAllSubmitFailed,
  } = useProjectCodeValidator();

  //* ────────────────────────────────────────────────────────────────────────────────────────
  //* ──── effects ───────────────────────────────────────────────────────────────────────────
  //* ────────────────────────────────────────────────────────────────────────────────────────

  useEffect(() => {
    const { onSetCurrentMenu } = app;
    onSetCurrentMenu('smartsupport');
    setStarterVisible(true);
  }, []);

  useEffect(() => {
    if (!users.file) {
      return;
    }
    const tblCol = [];
    columns.forEach((column) => {
      if (column.isRequired || users.data.some((row) => !!row[column.key])) {
        tblCol.push({
          title: column.header,
          key: column.key,
          dataIndex: column.key,
          ellipsis: true,
          width: column.width ? column.width : null,
        });
      }
    });
    setTableColumns(tblCol);
  }, [users]);

  useEffect(() => {
    console.log('useEffect');
    if (submitSucceeded) {
      onReset();
      Modal.success({
        title: 'Success',
      });
    }
    if (submitFailed) {
      Modal.error({
        title: 'Error',
      });
    }
  }, [submitSucceeded, submitFailed]);

  useEffect(() => {
    if (invalidRowsFounded.length > 0) {
      const reduced = reduceInvalidRows(invalidRowsFounded);
      Object.entries(reduced).forEach(([key, value]) => {
        notification.error({
          message: `Error en la ${key}`,
          description: `No se encontraron datos en la(s) column(as) ${value.reduce(
            (acc, err, index) => {
              return (
                acc + err.header + (index === value.length - 1 ? '' : ', ')
              );
            },
            ''
          )}`,
          duration: 6,
        });
      });
    }

    return () => {
      if (invalidRowsFounded > 0) setInvalidRowsFounded([]);
    };
  }, [invalidRowsFounded]);

  //* ────────────────────────────────────────────────────────────────────────────────
  //* ──── functions ─────────────────────────────────────────────────────────────────
  //* ────────────────────────────────────────────────────────────────────────────────

  function onSubmit() {
    const invalidRows = checkInvalidRows(users.data);
    if (invalidRows && invalidRows.length > 0) {
      const reduced = reduceInvalidRows(invalidRows);
      Object.entries(reduced).forEach(([key, value]) => {
        notification.error({
          message: `Error en la ${key}`,
          description: `No se encontraron datos en la(s) column(as) ${value.reduce(
            (acc, err, index) => {
              return (
                acc + err.header + (index === value.length - 1 ? '' : ', ')
              );
            },
            ''
          )}`,
          duration: 6,
        });
      });
      return;
    }
    let actionInvalid = false;
    users.data.forEach((row, index) => {
      if (Object.prototype.hasOwnProperty.call(row, 'action')) {
        const validActions = ['new', 'resume', 'pause', 'del'];
        const rowAction = row.action.replace(/\s+/g, '').toLowerCase();
        if (!validActions.includes(rowAction)) {
          notification.error({
            message: `Action invalido en la fila ${index + 1}`,
            description: 'NEW - RESUME - PAUSE - DEL',
            duration: 6,
          });
          actionInvalid = true;
        }
      }
    });

    if (actionInvalid) {
      return;
    }

    // let projectCodeInvalid = false;
    // users.data.forEach((row) => {
    //   const list = projectCodeList.map((pc) => pc.code);
    //   console.log(list);
    //   if (!list.includes(row.projectCode)) {
    //     notification.error({
    //       message: `Project Code invalido encontrado`,
    //       duration: 6,
    //     });
    //     projectCodeInvalid = true;
    //   }
    // });
    // if (projectCodeInvalid) {
    //   return;
    // }
    if (!projectCode) {
      notification.error({
        message: 'Debe seleccionar la plataforma a provisionar',
        duration: 4,
      });
      return;
    }

    const { projects, userId } = user;
    const projectCodeId = projects.find((p) => p.name === projectCode).code;
    const data = users.data.map((u) => {
      return { ...u, projectCode: projectCodeId, userId };
    });
    submit(data, users.file);
  }

  function onReset() {
    setUsers({ data: [], file: null, headers: [] });
    setTableColumns([]);
  }

  function onStartClick() {
    setStarterVisible(false);
    setRestVisible(true);
  }

  function onShowInfo() {
    setStarterVisible(true);
    setRestVisible(false);
  }

  function getProjectsOptions() {
    const { projects } = user;
    const list = projects.map((p) => {
      return { value: p.name, label: p.description };
    });
    return list.filter((e) => e.value.startsWith('SSC'));
  }

  //* ────────────────────────────────────────────────────────────────────────────────────────
  //* ──── component ─────────────────────────────────────────────────────────────────────────
  //* ────────────────────────────────────────────────────────────────────────────────────────

  return (
    <Layout>
      {starterVisible && <GettingStarted {...gettingStartedProps} />}
      {restVisible && (
        <Spin spinning={submitRequested}>
          <Content>
            {!users.file && (
              <UsersLoader
                onLoad={(e) => {
                  setUsers({
                    data: e.data,
                    file: e.file,
                    headers: e.headers,
                  });
                }}
                columns={columns}
              />
            )}
            {users.file && (
              <div>
                <UsersTable
                  columns={tableColumns}
                  dataSource={users.data}
                  size="small"
                  tableLayout="fixed"
                  scroll={{ x: 1300 }}
                />
                <div css={{ display: 'flex', justifyContent: 'space-between' }}>
                  <ProjectCodeSelector
                    options={getProjectsOptions()}
                    onChange={(code) => setProjectCode(code)}
                    value={projectCode}
                  />
                  <Actions
                    onSubmit={onSubmit}
                    onReset={onReset}
                    onShowInfo={onShowInfo}
                  />
                </div>
              </div>
            )}
          </Content>
        </Spin>
      )}
    </Layout>
  );
}

export default SmartsupportProvisionerPage;
