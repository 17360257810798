import React, { useEffect, useState } from 'react';

import useSubmissionHistory from '../../hooks/useSubmissionHistory';
import { useIdentity } from 'fractalia-provisioner/identity';
import UserProfile from '../../components/UserProfile';

import WelcomePageLayout from './WelcomePageLayout';
import WelcomePageContent from './WelcomePageContent';
// import WelcomePageTitle from '../components/WelcomePageTitle';
import { Spin } from 'fractalia-design';

import UserSubmissionHistoryWidget from '../../components/UserSubmissionHistoryWidget';
import UserProjectsPermissionWidget from '../../components/UserProjectsPermissionWidget';
import { css } from '@emotion/core';

const pageStyle = css`
  .page-content: {
    blackground-color: #fff;
  }
`;

function WelcomePage() {
  /**
   * constantes
   */
  const userSubmissionHistoryTableHeaderText = 'Histórico de ficheros subidos';
  const userProjectsAllowedTableHeaderText = 'Proyectos en los que puede provisionar usuarios';

  // hooks
  const { user } = useIdentity();
  const [permissionData, setPermissionData] = useState([]);
  const [submissionHistoryDataLocal, setSubmissionHistoryDataLocal] = useState([]);

  const {
    submissionHistoryFetch,
    submissionHistoryFetchRequested,
    submissionHistoryFetchSucceeded,
    submissionHistoryFetchFailed,
    submissionHistoryData,
  } = useSubmissionHistory();

  // effects
  useEffect(() => {
    submissionHistoryFetch();
    console.log('effect');
    setPermissionData(
      user.projects.map((project) => {
        return { description: project.description, partner: project.partner };
      })
    );
  }, []);

  useEffect(() => {
    setSubmissionHistoryDataLocal(submissionHistoryData);
  }, [submissionHistoryFetchSucceeded]);

  return (
    <WelcomePageLayout css={pageStyle}>
      <Spin spinning={submissionHistoryFetchRequested}>
        <WelcomePageContent>
          <div>
            <UserSubmissionHistoryWidget headerText={'Histórico de ficheros subidos'} data={submissionHistoryDataLocal} />
          </div>
          <div css={{ maxWidth: '80%' }}>
            <UserProjectsPermissionWidget headerText={'Proyectos en los que puede provisionar usuarios/licencias'} data={permissionData} />
          </div>
          <div>
            <h3 class="css-1tquyt-styles header">Últimos cambios realizados .-</h3>
            <ul>

              <li>
                2022-05-30
                <ul>
                  <li>
                    Smartsupport
                    <ul>
                      <li>
                        Aparecen 3 nuevas columnas que identifican a un usuario como empleado/hijo (&quot;<b>ParentCompany Identification</b>&quot;, &quot;<b>ParentCompany License</b>&quot; y &quot;<b>ParentCompany Email</b>&quot;). Al administrador/padre de un usuario empleado/hijo se le puede identificar por su &quot;<b>Identification</b>&quot;, &quot;<b>License/Eid</b>&quot; o &quot;<b>Email</b>&quot;.
                      </li>
                      <li>
                        El usuario administrador/padre de la pyme, podrá definir el número total de usuarios (incluido él) por medio de la columna &quot;<b>Company Total Licenses</b>&quot;.
                      </li>
                      <li>
                        Nuevos ejemplos de ficheros excels.
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>

              <li>
                2021-07-16
                <ul>
                  <li>
                    Smartsupport
                    <ul>
                      <li>
                        Renombrado de algunas columnas. La columna <b>&quot;Nombre Empresa&quot;</b> se cambia por <b>&quot;Company Name&quot;</b>{' '}
                        aunque siguen siendo las dos válidas. También se cambia la columna <b>&quot;Fecha de solicitud&quot;</b> por{' '}
                        <b>&quot;Request Date&quot;</b> pero ambas siguen siendo válidas aunque esta columna se encuentra actualmente en desuso.
                      </li>
                      <li>
                        Se añade la columna <b>&quot;Company Identification&quot;</b>. Es necesaria si se quiere provisionar las licencias/usuarios
                        con nombre de empresa.
                      </li>
                      <li>
                        Se añaden nuevos ejemplos de ficheros excels.
                      </li>                      <li>
                        Se añade la columna <b>&quot;Lote&quot;</b> con el fin de identificar compañías de provisión (agrupación por lotes).
                      </li>
                      <li>
                        Si en la columna de <i>Action</i> se introduce <b>&quot;DELETE&quot;</b>, se entiende como <b>&quot;DEL&quot;</b> (sinónimos)
                      </li>
                      <li>Recordamos que apartir de ahora, sólo es posible la asignación de una suite por usuario.</li>
                    </ul>
                  </li>
                </ul>
              </li>

              <li>
                2021-03-26
                <ul>
                  <li>Cambian algunos literales.</li>
                  <li>
                    Smartsupport
                    <p>
                      La plantilla excel incorpora una nueva columna llamada <b>&quot;companyMaxNumLicensesAllowed&quot;</b> de tipo numérico en la
                      que se puede introducir el número de hijos/empleados que pueden ser provisionados con la licencia/usuario que se va a dar de
                      alta. Sólo para el modelo multiusuario (usuarios que gestionan otros usuarios). <u>Opcional</u>
                    </p>
                  </li>
                </ul>
              </li>

              <li>
                2021-03-25
                <ul>
                  <li>
                    Smartsupport
                    <p>
                      Las <i>únicas columnas obligatorias</i> pasan a ser <b>LOGIN</b> y <b>ACTION</b>. Sea consciente de que si un usuario/licencia
                      no se provisiona con email, no se le podrá enviar mail de bienvenida.
                    </p>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </WelcomePageContent>
      </Spin>
    </WelcomePageLayout>
  );
}

export default WelcomePage;
